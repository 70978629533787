<template>
  <div
    class="swiper-content"
    :class="{ 'swiper-content--grey-bg': colorBg === 'grey' }"
  >
    <swiper
      :options="{
        slidesPerView: 'auto',
        touchRatio: 2,
        spaceBetween: spaceBetween,
        navigation: {
          nextEl: '.next',
          prevEl: '.prev'
        }
      }"
    >
      <swiper-slide
        v-for="(img, index) in images"
        :key="index"
      >
        <img :src="img.path" />
        <span class="swiper-title"> {{ img.title }} </span>
      </swiper-slide>
    </swiper>
    <div v-if="isNavigation" class="navigation-panel">
      <swiper-nav type="default-prev" class="prev" />
      <swiper-nav type="default-next" class="next" />
    </div>
  </div>
</template>

<script>
import SwiperNav from "@/components/Parts/SwiperNav";

export default {
  name: "SwiperImage",
  components: {
    SwiperNav,
  },
  props: {
    images: {
      type: Array,
      default: () => ([])
    },
    colorBg: {
      type: String,
      default: ""
    },
    swiperClass: {
      type: String,
      default: "swiper-wrapper"
    },
    spaceBetween: {
      type: Number,
      default: 10
    },
    isNavigation: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.swiper-content {
  position: relative;
  padding-left: 124px;
  padding-top: 101px;
  padding-bottom: 125px;
  background-color: #111;

  .swiper-slide {
    text-align: left;
  }

  .navigation-panel {
    position: absolute;
    right: 10%;
    bottom: 15%;
    z-index: 1;
    display: flex;
  }

  .swiper-title {
    display: block;
    width: 60%;
    margin-top: 31px;
    color: rgba(#fff, 0.5);
    font-size: 26px;
    line-height: 34px;
    text-align: left;
  }

  &--grey-bg {
    background-color: #1a1a19;
  }
}

</style>
