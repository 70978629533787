var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swiper-content",class:{ 'swiper-content--grey-bg': _vm.colorBg === 'grey' }},[_c('swiper',{attrs:{"options":{
      slidesPerView: 'auto',
      touchRatio: 2,
      spaceBetween: _vm.spaceBetween,
      navigation: {
        nextEl: '.next',
        prevEl: '.prev'
      }
    }}},_vm._l((_vm.images),function(img,index){return _c('swiper-slide',{key:index},[_c('img',{attrs:{"src":img.path}}),_c('span',{staticClass:"swiper-title"},[_vm._v(" "+_vm._s(img.title)+" ")])])}),1),(_vm.isNavigation)?_c('div',{staticClass:"navigation-panel"},[_c('swiper-nav',{staticClass:"prev",attrs:{"type":"default-prev"}}),_c('swiper-nav',{staticClass:"next",attrs:{"type":"default-next"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }