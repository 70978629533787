<template>
  <div class="year-memory-screen">
    <div class="content">
      <swiper
          class="swiper"
          :options="swiperOption"
      >
        <swiper-slide
            v-for="(img, index) in gallery"
            :key="index"
        >
          <img :src="img.path"/>

        </swiper-slide>
      </swiper>
      <h1 class="title">
        Активный житель: новая площадка для того, чтобы сделать наш город лучше
      </h1>
      <h3 class="title-description">
        У подвига поколения Победителей и благодарной памяти их потомков нет и не может быть срока давности, меры,
        границ
      </h3>
      <a href="#" class="link">
        Подробнее
        <arrows-yellow class="arrow"/>
      </a>
      <div class="about-project">
        <h3 class="about-project__title">
          О проекте
        </h3>
        <p class="about-project__text">
          Сообщайте о городских проблемах, участвуйте в интерактивном голосовании на актуальные темы городской жизни.
          Вместе сделаем город лучше
        </p>
      </div>
      <div class="content-swiper content-swiper--one">
        <swiper-image
            class="content-swiper__swiper"
            :space-between="30"
            :images="images_1"
            :is-navigation="images_1.length > 1"
        />
        <h2 class="content-swiper__title">
          Обращения
        </h2>
        <p class="content-swiper__description">
          Лужа перед подъездом, яма на дороге, сломался пандус? Сообщите о проблеме в один клик и отслеживайте ход работы онлайн. Также в разделе «Обращения» вы можете посмотреть все заявки от ваших земляков.
        </p>
      </div>
      <div class="content-swiper content-swiper--two">
        <swiper-image
            class="content-swiper__swiper"
            :space-between="30"
            :images="images_2"
            :is-navigation="images_2.length > 1"
        />
        <h2 class="content-swiper__title">
          Голосования
        </h2>
        <p class="content-swiper__description">
          В разделе «Голосования» примите участие в решении актуальных городских вопросов: нужно ли построить новую дорогу в районе? Или лучше отремонтировать соседнюю школу?
          Ставить ли живую елку на главной площади или в этом одну сделать акцент на украшениях в разных районах города?
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowsYellow from "@/components/Icons/ArrowsYellow";
import SwiperImage from "@/components/Parts/SwiperImage";
import DetailedButton from "@/components/Parts/DetailedButton";

export default {
  components: {
    ArrowsYellow,
    SwiperImage,
    DetailedButton
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 37,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        centeredSlides: true,
      },
      gallery: [
        {
          path: require("@/assets/img/banners/vlgdnsk_banner_main.jpg")
        },
      ],
      images_1: [
        {
          path: require("@/assets/img/banners/vlgdnsk_banner_1.jpg")
        }
      ],
      images_2: [
        {
          path: require("@/assets/img/banners/vlgdnsk_banner_2.jpg")
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.year-memory-screen {
  .content {
    height: 1555px;
    overflow-x: hidden;
    overflow-y: auto;

    .swiper {
      height: 510px;
      margin-top: 70px;

      .swiper-slide {
        width: 884px;
        height: 442px;
        margin-right: 12px;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .swiper-pagination-bullet {
        width: 14px;
        height: 14px;
        background: rgba(#fff, 0.2);
      }

      .swiper-pagination-bullet-active {
        background: #007aff;
      }
    }
  }

  .title {
    margin-top: 66px;
    font-size: 72px;
    font-weight: 500;
    line-height: 80px;
  }

  .title-description {
    margin: 0 124px;
    font-size: 32px;
    font-weight: normal;
    line-height: 46px;
    color: rgba(255, 255, 255, 0.75);
  }

  .about-project {
    padding: 135px 124px 495px 124px;
    background-color: #091F52;
    text-align: left;

    &__title {
      font-size: 60px;
      font-weight: 500;
      color: #fff;
    }

    &__text {
      padding-top: 48px;
      font-size: 32px;
      color: rgba(#fff, 0.75);
      text-align: left;
    }
  }

  .content-swiper {
    position: relative;
    text-align: left;

    &__swiper {
      .swiper-slide {
        width: 884px;
        height: 469px;
        border-radius: 12px;
        overflow: hidden;

        img {
          width: 884px;
          height: 469px;
          object-fit: cover;
        }
      }
    }

    .swiper-content {
      padding-top: 0;
      background-color: rgba(#fff, 0.0);

      .navigation-panel {
        bottom: 5%;
      }
    }

    &__title {
      padding: 0 124px;
      font-size: 48px;
      font-weight: 500;
    }

    &__description {
      padding: 0 124px;
      font-size: 32px;
      font-weight: normal;
      color: rgba(#fff, 0.75);
    }

    &__detailed-btn {
      padding-top: 44px;
    }

    &--one {
      top: -330px;
    }

    &--two {
      top: 0;
      height: 850px;
      background: #091F52;
      padding-top: 350px;

      .swiper-content {
        position: absolute;
        top: -200px;
      }
    }
  }

  .content-info {
    position: relative;
    background-color: #111;
    text-align: left;

    .wrapper {
      position: relative;
      top: -250px;
    }

    &__photo-wrapper {
      width: 884px;
      height: 442px;
    }

    &__photo {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: relative;
      padding: 0 124px;
    }

    &__text {
      position: relative;

      &-title {
        padding: 0 124px;
        padding-top: 68px;
        padding-bottom: 36px;
        font-size: 48px;
        font-weight: 500;
        line-height: 56px;
        opacity: 1;
      }

      &-description {
        padding: 0 124px;
        font-size: 32px;
        font-weight: normal;
        line-height: 46px;
        color: rgba(#fff, 0.75);
      }

      &-detailed-btn {
        position: relative;
        margin-bottom: 136px;
      }
    }

    &--grey {
      background-color: #242423;
    }
  }
}
</style>
